import React from "react";
import { NavBarItemLabel, NavItemUI } from "./UI"

export const NavBarItem = ({isCurrent, onClick, index, linkLabel}: any) => {
    return 	(
        <NavItemUI key={`item-`+ index} className="nav-item">
            <NavBarItemLabel
                className="nav-link"
                isActive={isCurrent}
                onClick={() => onClick(index)}>
                {linkLabel}
            </NavBarItemLabel>
        </NavItemUI>
    )
}
export default NavBarItem;