import React from 'react';
//import logo from './logo.svg';
import './App.css';
import About from './components/about/about';
import Banner from './components/common/banner/banner';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
import HomeBanner from './components/homeBanner/homeBanner';
import NavBar from './components/navBar/navBar';
import Services from './components/services/service';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
      </header> */}
        <NavBar/>
        <HomeBanner/>
        <Services/>
        <About/>
        <Banner/>
        <Contact/>
        <Footer/>
    </div>
  );
}

export default App;
