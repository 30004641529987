import styled from "styled-components";
import { PrimaryWord } from "../../style/common/style";
import { mediaQueryMobile } from "../../style/common/media";

export const H1DesktopStyle = `
    margin:auto;
    vertical-align: middle;
    font-size: 3.5rem;
    font-family: "Open Sans,sans-serif";
    font-weight: 100;
`;

export const HomeBannerContainerUI = styled.div`
    background-image: url("/images/home-banner.jpg");
    height: 100vh;
    width: 100%;
    position: relative;
    z-index: 1;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: ${mediaQueryMobile}) {
        ::before {
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0,0,0,.75);
            width: 100%;
            height: 100%;
            z-index: -1;
            content: "";
        }
    }
    @media screen and (min-width: ${mediaQueryMobile}) {
        background-image: url("/images/home-banner-1.jpg");
        padding: 0 1.4rem;
    }
`;

export const HomeContentUI = styled.div`
    position: relative;
    top: 50%;
    @media screen and (min-width: ${mediaQueryMobile}) {
        top: 30%;
    }
`;

export const HomeContentH1UI = styled.h1`
    ${H1DesktopStyle}
    color: #fff;
    span {
        ${PrimaryWord}
    }
    font-size: 2.3rem;
    /* margin: 1.6rem 0;
    font-size: 32px;
    line-height: 43px; */
    @media screen and (min-width: ${mediaQueryMobile}) {
        font-size: 3.5rem;
	}
`;

export const HomeContentH2UI = styled.h2`
    margin:auto;
    line-height:51px;
    vertical-align: middle;
    font-size: 1.5rem;
    font-family: "Open Sans,sans-serif";
    font-weight: 100;
    color: #fff;
    span {
            ${PrimaryWord}
        }
    display:none;
    @media screen and (min-width: ${mediaQueryMobile}) {
        display: block;
        font-size: 3.5rem;
	}

`;