import { BannerButtonUI, BannerButtonLabel, BannerContainerUI, BannerContentUI, BannerTitleUI, SvgButtonStyled } from "./UI";

export const Banner = () => {
    return (
        <BannerContainerUI>
            <BannerContentUI>
                <BannerTitleUI>Discover <b>Our</b> Work ?</BannerTitleUI>
                <BannerButtonUI>
                    <BannerButtonLabel>
                        Click Me
                    </BannerButtonLabel>
                    <SvgButtonStyled/>
                </BannerButtonUI>
            </BannerContentUI>
        </BannerContainerUI>
    )
}
export default Banner;