import React from "react";
import { FooterContainerUI, FooterContentUI, FooterLinkUI, FooterOtherLinkUI, ImgUI } from "./UI";

export const Footer = () => {
    const date = new Date().getFullYear();

    return (
        <FooterContainerUI>
            <FooterContentUI>
                <FooterLinkUI>
                    <ImgUI alt="Facebook" src="/images/fb.png"/>
                </FooterLinkUI>
                <FooterLinkUI>      
                    <ImgUI alt="Instagram" src="/images/insta.png"/>
                </FooterLinkUI>
                
            </FooterContentUI> 
            <FooterContentUI>© {date} - Helios Cloud Solutions</FooterContentUI> 
            <FooterContentUI>
                <FooterLinkUI>
                    <ImgUI alt="LinkedIn" src="/images/linkedin.png"/>
                </FooterLinkUI>
                <FooterLinkUI>      
                    <ImgUI alt="Youtube" src="/images/youtube.png"/>
                </FooterLinkUI>
            </FooterContentUI> 
            <FooterOtherLinkUI target="_blank" rel="noreferrer" href="https://icons8.com"></FooterOtherLinkUI>
        </FooterContainerUI>
    )
}
export default Footer;