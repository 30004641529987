import React from "react";
import Box from "../common/box/box";
import { ContactContainerUI, ContactContentUI, ContentDescriptionUI, ContentTitleUI } from "./UI";
export const Contact = () => {
        const contact = [
            {
                title: "Location",
                logo: "/images/map.png",
                description: "140 rue du Général Leclerc - 95130 Franconville",
                url: "https://goo.gl/maps/AHhxNcb7Do1PMVrj6",
                target: "_blank"
            },
            {
                title: "Contact",
                logo: "/images/mail.png",
                description: "contact@helios-cloud-solutions.com",
                url: "mailto:contact@helios-cloud-solutions.com"
            },
            {
                title: "Phone",
                logo: "/images/phone.png",
                description: "(+33) - 06 51 29 61 02",
                url: "tel:+33651296102"
            }
        ]

    return (
        <ContactContainerUI data-section="contact" className="container">
            <ContentTitleUI>
                <b>Contact</b>
            </ContentTitleUI>
            <ContentDescriptionUI>
                You can found <b>US</b>
            </ContentDescriptionUI>
            <ContactContentUI className="contact-content">
                {contact.map((content) => 
                    <Box content={content} className="contactBox"/>
                )}                
            </ContactContentUI>
        
        </ContactContainerUI>
    )

}
export default Contact;