import styled, { css } from "styled-components";
import { PrimaryUnderline } from "../../style/common/style";
import { mediaQueryMobile } from "../../style/common/media";

const bColor = css`
    b {
        ${PrimaryUnderline}
    }
`;

export const ContactContainerUI = styled.section`
    min-height: 20rem;
`;
export const ContactContentUI = styled.div`
    display: block;
    ${bColor}
    .box-title {
        display: none;
    }
    .contactBox {
        min-height: 8rem;
    }

    @media screen and (min-width: ${mediaQueryMobile}) {
        display: flex;
    }
    
`;

export const ContentTitleUI = styled.h2`
    font-size: 3rem;
    font-weight: 600;
    ${bColor}
`;

export const ContentDescriptionUI = styled.p`
    font-size: 1.2rem;
    font-weight: 300;
    ${bColor}
`;