import React, { FC } from "react";
import { BoxContainerUI, BoxContentUI, BoxDescriptionUI, BoxIconUI, BoxImgUI, BoxLabelUI, BoxLinkUI } from "./UI";

interface BoxInterface {
    content?: any;
    className?: any;
}

export const Box:FC<BoxInterface> = ({ content, className = "" }) => {
    return (
        <BoxContainerUI className={`box-container ${className}`}>
            <BoxLinkUI href={content?.url} target={content?.target}>
                <BoxContentUI className={className}>
                    <BoxIconUI>
                        <BoxImgUI alt={content?.title} src={content?.logo}/>
                    </BoxIconUI>
                    <BoxLabelUI className="box-title">
                        {content?.title}
                    </BoxLabelUI>
                    <BoxDescriptionUI>
                        {content?.description}
                    </BoxDescriptionUI>
                </BoxContentUI>
            </BoxLinkUI>
        </BoxContainerUI>
    )
}

export default Box;