import React from "react";
//import { isMobile } from 'react-device-detect';
import { HomeBannerContainerUI, HomeContentH1UI, HomeContentH2UI, HomeContentUI } from "./UI";

export const HomeBanner = () => {
    return (
        <HomeBannerContainerUI id="home" data-section="home">
            <HomeContentUI>
                <HomeContentH1UI>Designing your online <span>brand</span></HomeContentH1UI><br/>
                <HomeContentH2UI>with <span>expert</span> web development and design</HomeContentH2UI>
            </HomeContentUI>
        </HomeBannerContainerUI>
    )
}

export default HomeBanner;