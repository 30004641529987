import styled from "styled-components";
import { primary } from "../../style/common/color";
import { PrimaryUnderline } from "../../style/common/style";
import { mediaQueryMobile } from "../../style/common/media";

export const ServicesContainerUI = styled.section`
    @media screen and (min-width: ${mediaQueryMobile}){
        margin-top: 3rem;
    }
`;
export const ServicesTitleUI = styled.h2`
    font-size: 3rem;
    font-weight: 600;
    b {
        ${PrimaryUnderline}
    }
`;

export const ServicesContentUI = styled.div`
    display: block;
    margin-bottom: 1rem;
    @media screen and (min-width: ${mediaQueryMobile}){
        display: flex;
        margin-bottom: 2.5rem;
    }
`;
export const ServiceBoxUI = styled.div`
    flex: 0 0 auto;
    width: 32%;
    padding-right: calc(1rem * .5);
    padding-left: calc(1rem * .5);
`;
export const ServiceBoxContentUI = styled.div`
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgb(0 0 0 / 10%);
    padding: 35px 30px;
    text-align: center;
    margin-bottom: 30px;
    transition: .5s;
    :hover{
        background-color: ${primary};
        transform: translateY(-10px);
        color: white;
    }
`;

export const ServiceBoxIconUI = styled.div`
    font-size: 45px;
    transition: .5s;
    fill: ${primary};
    line-height: 1;
    img{
        width: 3rem;
        height: 3rem;
    }
`;
export const ServiceBoxLabelUI = styled.span`
    font-size: 1.4rem;
    font-weight: 500;
`;
export const ServiceBoxDescriptionUI = styled.p``;