import styled from "styled-components";
import { primary } from "../../../style/common/color";
import { PrimaryUnderline } from "../../../style/common/style";
import {SvgButton} from "../../../style/image/svgButton";
import { mediaQueryMobile } from "../../../style/common/media";

export const BannerContainerUI = styled.div`
    position: relative;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('/images/home-banner.jpg');
    height: 12rem;
    padding: 1rem 2rem;
    @media screen and (min-width: ${mediaQueryMobile}) {    
        background-attachment: fixed;
        height: 8rem;
        padding: 4rem 8rem;
	}
`;

export const SvgButtonStyled = styled(SvgButton)``

export const BannerContentUI = styled.div`
    text-align: center;
    svg {
        position: relative;
        top: 0;
        margin-left: 10px;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: ${primary};
        stroke-width: 2;
        transform: translateX(-5px);
        transition: all 0.3s ease;
    }
`;

export const BannerTitleUI = styled.h2`
    font-size: 2.5rem;
    color: white;
    font-weight: 500;
    line-height: 48px;
    text-transform: capitalize;
    b {
        ${PrimaryUnderline}
    }
`;

export const BannerLinkButtonUI = styled.button`
    background-color: ${primary};
    color: white;
    box-shadow: 0 8px 6px -6px rgba(50,51,51,.4);
    border: none;
    padding: 15px 30px;
    border-radius: 0;
    transition: .5s;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
`;

export const BannerButtonUI = styled.a` 
    position: relative;
    margin: auto;
    padding: 15px 22px;
    transition: all .2s ease;
    text-decoration: none;
    color: inherit;
    cursor: pointer;   
  
    :before {
        content: "";
        position: absolute;
        top: -4px;
        left: 0;
        display: block;
        border-radius: 28px;
        background: #ffffff;
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
        opacity: 0.7;
        
        transform: translateX(0);
    }

    :hover {
        :before {
            width: 100%;
            background-color: #111;
        }
        ${SvgButtonStyled}{
            transform: translateX(0);
        }
    }

    :active {
        transform: scale(0.96);
    }

`;
export const BannerButtonLabel = styled.span`
    position: relative;
    font-size: 19px;
    line-height: 18px;
    font-weight: 900;
    letter-spacing: .25em;
    text-transform: uppercase;
    vertical-align: middle;
    color: ${primary};
`