import Box from "../common/box/box";
import { ServicesContainerUI, ServicesContentUI, ServicesTitleUI } from "./UI";

export const Services = () => {
    const serviceBoxContent1 = [
        {
        title: "Web Dev",
        logo: "/images/web.png",
        description: "Valuable visual assets. Strong brands have clear brand core values, an unequivocal positioning, and a long-term brand strategy"
        },
        {
        title: "Brands",
        logo: "/images/painting-icon.svg",
        description: "Valuable visual assets. Strong brands have clear brand core values, an unequivocal positioning, and a long-term brand strategy"
        },
        {
        title: "UI/UX",
        logo: "/images/design.png",
        description: "Valuable visual assets. Strong brands have clear brand core values, an unequivocal positioning, and a long-term brand strategy"
        }
    ]
    const serviceBoxContent2 = [
        {
        title: "Mobile Dev",
        logo: "/images/mobile.png",
        description: "Valuable visual assets. Strong brands have clear brand core values, an unequivocal positioning, and a long-term brand strategy"
        },
        {
        title: "Visit Cards",
        logo: "/images/card.png",
        description: "Valuable visual assets. Strong brands have clear brand core values, an unequivocal positioning, and a long-term brand strategy"
        },
        {
        title: "Cloud Solutions",
        logo: "/images/cloud.png",
        description: "Valuable visual assets. Strong brands have clear brand core values, an unequivocal positioning, and a long-term brand strategy"
        }
    ]

    return (
        <ServicesContainerUI id="services" data-section="services" className="container">
            <ServicesTitleUI>
                <b>Services</b>
            </ServicesTitleUI>
            <ServicesContentUI>
                {serviceBoxContent1.map((content) =>
                    <Box content={content} className="serviceBox"/>
                )}
            </ServicesContentUI>
            <ServicesContentUI>
                {serviceBoxContent2.map((content) =>
                    <Box content={content} className="serviceBox"/>
                )}
            </ServicesContentUI>
        </ServicesContainerUI>
    )
}

export default Services;