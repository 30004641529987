import { css } from "styled-components";
import { primary } from "./color";

export const PrimaryUnderline = css`
    background: linear-gradient(180deg,transparent 90%,#f8b21f 0);
    background-size: 100px 1.2em;
    font-weight: 700;
    font-family: "PT Sans,sans-serif";
`;

export const PrimaryWord = css`
    background-size: 100px 1.2em;
    font-weight: 700;
    font-family: "PT Sans,sans-serif";
    color: ${primary};
`;