import styled, { css } from "styled-components";
import { PrimaryUnderline } from "../../style/common/style";
import { H1DesktopStyle } from "../homeBanner/UI";
import { mediaQueryMobile } from "../../style/common/media";

export const AboutContainerUI = styled.section`
    display: block;
`;
export const AboutContentUI = styled.div`
    display: block;
    @media screen and (min-width: ${mediaQueryMobile}) {
        display: flex;
	}
`;

const bColor = css`
    b {
        ${PrimaryUnderline}
    }
`;

export const AboutRowUI = styled.div`
    padding-right: calc(0.5rem);
    padding-left: calc(0.5rem);
    text-align: center;
    @media screen and (min-width: ${mediaQueryMobile}) {
        width: 50%;
	}
`;
export const AboutRowRightImagesUI = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    img {
        max-width: 100%;
    }
    :hover{
        transition: .5s;
        transform: translateY(-10px);
    }
`;

export const AboutRowImg1UI = styled.img`
    border-radius: 25px 25px;
    @media screen and (min-width: ${mediaQueryMobile}) {
        border-radius: 25px 25px 0 0;
	}
`;
export const AboutRowImg2UI = styled.img`
    border-radius: 25px 25px;
    @media screen and (min-width: ${mediaQueryMobile}) {
        border-radius: 0 0 25px 25px;
	}
`;

export const AboutH1 = styled.h1`
    font-size: 2rem;
    font-weight: 100;
    ${bColor}
    @media screen and (min-width: ${mediaQueryMobile}) {    
        line-height: normal !important;
        ${H1DesktopStyle}
	}
`;

export const AboutDescriptionUI = styled.div`
    margin-top: 1rem;
    text-align: start;
`;

export const AboutDescriptionBlocUI = styled.p`
    font-size: 1.2rem;
`;

export const AboutTitleUI = styled.h2`
    font-size: 3rem;
    font-weight: 600;
    ${bColor}
`;