import styled from "styled-components";
import { primary } from "../../../style/common/color";
import { mediaQueryMobile } from "../../../style/common/media";

export const BoxContainerUI = styled.div`
    margin-bottom: 1rem;
    cursor: pointer;
    @media screen and (min-width: ${mediaQueryMobile}){
        width: 32%;
        margin-bottom: 0rem;
        padding-right: calc(1rem * .5);
        padding-left: calc(1rem * .5);
    }
`;

export const BoxContentUI = styled.div`
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgb(0 0 0 / 10%);
    padding: 30px 30px;
    text-align: center;
    transition: .5s;
    :hover{
        background-color: ${primary};
        transform: translateY(-10px);
        color: #fff;
    }
`;

export const BoxIconUI = styled.div`
    font-size: 45px;
    transition: .5s;
    fill: ${primary};
    line-height: 1;
    img{
        width: 3rem;
        height: 3rem;
    }
`;
export const BoxImgUI = styled.img`
    width: 3rem;
    height: 3rem;
`;

export const BoxLabelUI = styled.span`
    font-size: 1.4rem;
    font-weight: 500;
`;

export const BoxDescriptionUI = styled.p``;
export const BoxLinkUI = styled.a`
    color: #000000;
`;