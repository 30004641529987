/** @format */

import React from "react";

export const SvgBurgerMenu = ({ className = "" }) => (
	<svg
		className={"svg svgBurgerMenu " + className}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20">
		<g fill="none" fillRule="evenodd" transform="translate(1 3)">
			<mask id="b" fill="#fff">
				<path
					id="a"
					d="M17.056 12.187c.521 0 .944.405.944.906s-.423.907-.944.907H.944C.423 14 0 13.594 0 13.093c0-.5.423-.906.944-.906h16.112zm0-6.094c.521 0 .944.406.944.907 0 .5-.423.907-.944.907H.944C.423 7.907 0 7.5 0 7c0-.5.423-.907.944-.907h16.112zm0-6.093c.521 0 .944.406.944.907 0 .5-.423.906-.944.906H.944C.423 1.813 0 1.408 0 .907S.423 0 .944 0h16.112z"
				/>
			</mask>
			<g fill="#262626" mask="url(#b)">
				<path d="M-24-26h66v66h-66z" />
			</g>
		</g>
	</svg>
);

export default SvgBurgerMenu;