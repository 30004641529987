import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import NavBarItem from "./navBarItem";
import { BurgerButton, BurgerIcon, LogoUI, MenuItemLiUI, MenuLinkUI, MenuUI, NavBarContainerUI, NavBarContentUI, NavBarUI, Overlay } from "./UI";
import { getOffsetFromTopOfPage, scrollTo} from "../../helper/scroll";

const SCROLL_OFFSET = 200;

export const NavBar = () => {
	const [currentItem, setCurrentItem] = useState(0);
	const [isMenuOpen, setMenuOpen]= useState(false);
	const toggle = useCallback(() => setMenuOpen(v => !v), []);

	const getSectionsOffsets = useCallback(() => {
		return [
			getOffsetFromTopOfPage(document.querySelector("[data-section='home']")),
			getOffsetFromTopOfPage(document.querySelector("[data-section='services']")) -
				67,
			getOffsetFromTopOfPage(document.querySelector("[data-section='about']")) -
				67,
			getOffsetFromTopOfPage(document.querySelector("[data-section='contact']")) -
				300
		];
	}, []);

	const onClick = useCallback((index: any) => {
        setCurrentItem(index)
		const margin = 50;
        const elOffset = getSectionsOffsets()[index];
        setTimeout(() => scrollTo(elOffset, margin), 100);

	}, [getSectionsOffsets]);

	const onScroll = useCallback(() => {
		const offsets = getSectionsOffsets();
		let havePassedItem = 0;

		for (let i = 0; i < offsets.length; i++)
			if (window.scrollY + SCROLL_OFFSET + 1 >= offsets[i]) havePassedItem = i;

		setCurrentItem(havePassedItem);
	}, [getSectionsOffsets]);

	useEffect(() => {
		window.addEventListener("scroll", onScroll, false);

		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, [onScroll]);

	const goToLink = useCallback((index) => {
		onClick(index);
		toggle();
	}, [toggle, onClick])

    return (
    <NavBarUI id="navBar" className="navBar">
        <NavBarContainerUI className="bar-container">
			{isMobile &&
				<BurgerButton onClick={toggle}>
					<BurgerIcon />
				</BurgerButton>
			}

            <LogoUI>
				<img src="/images/black-t.png" alt="logo" />
			</LogoUI>
			{
				!isMobile && <NavBarContentUI className="nav">
					<NavBarItem
						isCurrent={currentItem === 0}
						onClick={() => onClick(0)}
						linkLabel="Home"
						index={0}
					/>
					<NavBarItem
						isCurrent={currentItem === 1}
						onClick={() => onClick(1)}
						linkLabel="Services"
						index={1}
					/>
					<NavBarItem
						isCurrent={currentItem === 2}
						onClick={() => onClick(2)}
						linkLabel="About"
						index={2}
					/>

					<NavBarItem
						isCurrent={currentItem === 3}
						onClick={() => onClick(3)}
						linkLabel="Contact"
						index={3}
					/>
				</NavBarContentUI>
			}
        </NavBarContainerUI>
		{isMenuOpen && 				
			<>
				<MenuUI id="menu">
					<MenuItemLiUI>
						<MenuLinkUI onClick={() => goToLink(0)}>Home</MenuLinkUI>
					</MenuItemLiUI>
					<MenuItemLiUI>
						<MenuLinkUI onClick={() => goToLink(1)}>Services</MenuLinkUI>
					</MenuItemLiUI>
					<MenuItemLiUI>
						<MenuLinkUI onClick={() => goToLink(2)}>About</MenuLinkUI>
					</MenuItemLiUI>
					<MenuItemLiUI>
						<MenuLinkUI onClick={() => goToLink(3)}>Contact</MenuLinkUI>
					</MenuItemLiUI>
				</MenuUI>
				
				<Overlay isOpen={isMenuOpen} onClick={toggle} />		
			</>

		}
    </NavBarUI>
    )
}
export default NavBar;