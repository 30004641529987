import styled from "styled-components";
import { mediaQueryMobile } from "../../style/common/media";

export const FooterContainerUI = styled.footer`
    height: 8rem;
    background-color: black;
    display: flex;
`;

export const FooterContentUI = styled.div`
    color: white;
    margin: auto;
    @media screen and (min-width: ${mediaQueryMobile}) {
        width: 33%;
    }
`;

export const ImgUI = styled.img`
    width: 3rem;
    height: 3rem;
`;

export const FooterLinkUI = styled.a`
    margin: .5rem;
    cursor: pointer;
`;

export const FooterOtherLinkUI = styled.a`
    display: none;
`;