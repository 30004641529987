import React from "react";
import { AboutContainerUI, AboutContentUI, AboutDescriptionBlocUI, AboutDescriptionUI, AboutH1, AboutRowImg1UI, AboutRowImg2UI, AboutRowRightImagesUI, AboutRowUI, AboutTitleUI } from "./UI";
export const About = () => {
    return (
    <AboutContainerUI data-section="about" className="container">
        <AboutTitleUI>
            <b>About</b>
        </AboutTitleUI>
        <AboutContentUI>
            <AboutRowUI className="about-RowImages">
                <AboutRowRightImagesUI>
                    <AboutRowImg1UI src="/images/about-image-1.jpg" className="img1"/>
                </AboutRowRightImagesUI>
                <AboutRowRightImagesUI>
                    <AboutRowImg2UI src="/images/about-image-2.jpg" className="img2"/>
                </AboutRowRightImagesUI>
            
            </AboutRowUI>
            <AboutRowUI>
            <AboutH1>Helios <b>Cloud</b> Solution</AboutH1>
            <AboutDescriptionUI>
                <AboutDescriptionBlocUI>
                    We are an amazing company and we do great things. You can see it through our impressive departments. That's how brilliant we are. But in all seriousness, we'd like to meet you. We may be able to help you.
                </AboutDescriptionBlocUI>
            </AboutDescriptionUI>
            </AboutRowUI>
        </AboutContentUI>
    </AboutContainerUI>
    )
}
export default About;