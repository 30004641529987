import styled, { css } from "styled-components"
import { primary } from "../../style/common/color";
import { mediaQueryMobile } from "../../style/common/media";
import SvgBurgerMenu from "../../style/image/svgBurger";

const content = `        
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: block;
    margin: 0px;
    width: 100%;
    height: 0px;
    border: 2px solid ${primary};
    border-radius: 2px;
    transform: none;
`

export const NavBarUI = styled.header`
    position: fixed;
    background-color: #ffffff;
    top: 0;
    width: 100%;
    z-index: 200;
    display: block;
    height: 5rem;
    align-items: center;
    
    /* tablet only */
	@media screen and (min-width: ${mediaQueryMobile}) {
        display: flex;
	}
`;

export const BurgerIcon = styled(SvgBurgerMenu)`
	width: 1.66666666666666666666rem;
	height: 1.66666666666666666666rem;
    &::after {
        content: "Menu";
        font-size: 1rem;
        color: rgb(43, 43, 43);
        margin-top: 0.166667rem;
    }
`;

export const BurgerButton = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: 2.33333rem;
    &::after {
        content: "Menu";
        font-size: 1rem;
        color: rgb(43, 43, 43);
        margin-top: 0.166667rem;
    }
`;

export const NavBarContainerUI = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    padding: 0 1rem;

        /* tablet only */
	@media screen and (min-width: ${mediaQueryMobile}) {
        display: flex;
        width: 100%;
        flex-wrap: inherit;
        align-items: center;
        justify-content: space-between;
	}
`;

export const NavBarContentUI = styled.ul`
    display: none;
    @media screen and (min-width: ${mediaQueryMobile}) {
        display: flex;
        flex-basis: auto;
        align-items: center;
	}
`;

export const NavBarListUI = styled.ul`
    flex-direction: row;
    display: flex;
    list-style: none;
`;

export const NavItemUI = styled.li`
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    list-style-type: none;
`;

export const LogoUI = styled.div`
    img {
        top: 0;
        position: absolute;
        left: calc(50% - 1.75rem);
        max-height: 5rem;
    }
    @media screen and (min-width: ${mediaQueryMobile}) {
        img {
            height: 7.5rem;
            left: 0;
        }
	}
`;

export const NavBarItemLabel = styled.a<{isActive?:boolean}>`
    position: relative;
    padding: 1rem 0px;
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    ${({isActive}) => isActive && css`
        :before {
            bottom: -13px;
            opacity: 1;
            visibility: visible;
        }
        :after{
            ${content}
        }
        color: ${primary};
    `}
    :hover{
        cursor: pointer;
        color: ${primary};
    }
    :hover:after {
        ${content}
    }
`;

export const MenuUI = styled.ul`
    position: absolute;
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    color: rgb(43, 43, 43);
    list-style: none;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    font-size: 1.16667rem;
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 15px;
    transform: translateY(0%);
    pointer-events: all;
    transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
    z-index: 2;
`;

export const MenuItemLiUI = styled.li`
    display: flex;
    flex-direction: column;
    margin: 0px;
    width: 100%;
    border-bottom: 1px solid rgb(234, 234, 234);
    list-style: none;
`;

export const Overlay = styled.span<{ isOpen: boolean }>`
	display: block;
	position: absolute;
	top: 5.8333333rem;
	right: 0;
	left: 0;
	width: 100%;
	height: calc(100vh - 5.8333333rem);
	background-color: rgba(255, 255, 255, 0.7);
	pointer-events: ${({ isOpen }) => (isOpen ? "all" : "none")};
	opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
	transition: 0.3s opacity;
`;

export const MenuLinkUI = styled.a`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    transition: color 0.15s ease 0s;
`;